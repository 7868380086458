<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>لیست فرم ها</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            class="position-relative"
            :items="formsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <router-link :to="`/pages/forms/${data.item.formId}`">
                  <feather-icon
                    size="20"
                    icon="EditIcon"
                    class="text-primary"
                  />
                </router-link>
                <router-link :to="`/pages/forms/${data.item.formId}`">
                  <feather-icon
                    size="20"
                    icon="SettingsIcon"
                    class="text-primary"
                  />
                </router-link>
                <b-button
                  class="m-0 p-0"
                  variant="transparent"
                  size="sm"
                  @click="renderDeleteModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="TrashIcon"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- Delete Modal Start -->
    <b-modal
      id="modal-delete"
      centered
      ok-variant="danger"
      title="حذف فرم"
      ok-title="حذف"
      cancelTitle="انصراف"
      @ok="sendDeleteFormRequest"
    >
      <template v-if="tempForm">
        <span> آیا از حذف فرم "{{ tempForm.name }}" اطمینان دارید؟ </span>
      </template>
    </b-modal>
    <!-- Delete Modal End -->
    <!-- Edit Modal Start -->
    <b-modal
      id="modal-edit"
      centered
      size="lg"
      ok-variant="success"
      title="ویرایش فرم"
      ok-title="بروزرسانی"
      cancelTitle="انصراف"
      @ok="sendUpdateFormRequest"
    >
      <template v-if="tempForm">
        <b-container fluid>
          <b-form-row>
            <b-col cols="12" md="6">
              <b-form @submit.prevent>
                <b-form-group label="نام فرم" label-for="name">
                  <b-form-input
                    id="name"
                    trim
                    placeholder="تماس با ما یا ..."
                    v-model="tempForm.name"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12" md="6">
              <language-picker
                @getLanguage="setLanguage"
                :defaultLanguageId="tempForm.languageId"
              ></language-picker>
            </b-col>
          </b-form-row>
        </b-container>
      </template>
    </b-modal>
    <!-- Edit Modal End -->
  </b-overlay>
</template>

<script>
export default {
  title: "لیست فرم ها",
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllForms();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      formsList: null,
      myTableColumns: [
        {
          key: "formId",
          label: "شناسه فرم",
        },
        {
          key: "name",
          label: "نام فرم",
        },
        {
          key: "createDate",
          label: "تاریخ ایجاد",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
      tempForm: null,
    };
  },
  mounted() {
    this.getAllForms();
  },
  methods: {
    setLanguage(language) {
      this.tempForm.languageId = language.languageId;
    },
    async sendUpdateFormRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASingleForm = new UpdateASingleForm(_this);
        updateASingleForm.setRequestParamDataObj(_this.tempForm);
        await updateASingleForm.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فرم با موفقیت ویرایش شد.`,
              },
            });
            _this.tempForm = null;
            _this.getAllForms();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendDeleteFormRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteASingleForm = new DeleteASingleForm(_this);
        deleteASingleForm.setRequestParam({ id: _this.tempForm.formId });
        await deleteASingleForm.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فرم با موفقیت حذف شد.`,
              },
            });
            _this.tempForm = null;
            _this.getAllForms();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(form) {
      this.tempForm = JSON.parse(JSON.stringify(form));
      this.$bvModal.show("modal-delete");
    },
    renderEditModal(form) {
      this.tempForm = JSON.parse(JSON.stringify(form));
      this.$bvModal.show("modal-edit");
    },
    async getAllForms() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        let _this = this;
        let getAllForms = new GetAllForms(_this);
        getAllForms.setRequestParam(reqParams);
        await getAllForms.fetch((response) => {
          if (response.isSuccess) {
            _this.formsList = response.data.forms;
            _this.totalCount = response.data.formsCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    LanguagePicker,
  },
};
import {
  GetAllForms,
  DeleteASingleForm,
  UpdateASingleForm,
} from "@/libs/Api/Form";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
